import React from "react"

import {Link} from 'gatsby-plugin-intl'
import Layout from "../components/Layout"
import Seo from "../components/Seo"

export default () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="container">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <Link 
            to="/" 
            style={{ background: 'var(--accentMain)', 
            padding: '.5rem 1rem',
            borderRadius: 'var(--rounded)',
            color: 'white',
            textDecoration: 'none',
            display: 'inline-block',
            marginTop: '1rem' }}>Get back home</Link>
    </div>
  </Layout>
)
